import {
  AttendanceStatus,
  AttendeeCheckInStatus,
  EventChainingAction,
  ExperienceFeedbackType,
  ParticipationStatus,
  PostSignupAsk,
} from 'app/enums'
import {Button, Link, Message, Typography} from 'components'
import {
  EMPTY_STREET_ADDRESS_FIELDS_FOR_IDENTITY,
  EMPTY_TRACKING_PARAMS,
} from 'app/constants'
import {formatSingleStartTime, iso8601ToMomentInTz} from 'util/time'
import {getOrganizationEventUrl, getOrganizationFeedUrl} from 'util/routing'

import ChainingModalStepper from 'events/modals/ChainingModalStepper'
import {F} from 'util/i18n'
import {FeedItem} from 'events/components'
import GroupSignupManagement from './GroupSignupManagement'
import JoinButton from 'events/schedule/JoinButton'
import {ModalTypes} from 'events/modals/enums'
import ParticipationItemFeedback from './ParticipationItemFeedback'
import ParticipationItemFeedbackModal from './ParticipationItemFeedbackModal'
import ShareModal from 'events/modals/ShareModal'
import {getOneLineAddress} from 'util/geo'
import {hasValidDonationPostSignupAskConfig} from 'util/organization'
import {isAtCapacity} from 'util/timeslot'
import {isEveryIdentityFieldFilledOut} from 'util/user'
import {maybeGetEventSubheading} from 'util/event'
import {partial} from 'util/common'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'
import {useState} from 'react'

// Styled component to make the Confirm/Confirmed/Cancel buttons wider to match the design.
const WideButtonContentWrapper = styled.div`
  width: 12rem;
`
const CancelButtonContentWrapper = styled.div`
  padding-left: 2rem;
  padding-right: 2rem;
`

const ParticipationItemContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: ${styles.space.m};
  margin-top: -${styles.space.m};

  /* TODO(ramil + jared) Figure out a better way of adding margin to the child components. */
  & > * {
    margin-top: ${styles.space.m};
  }
`

const FeedbackWrapper = styled.div`
  font-style: italic;
  margin-top: ${styles.space.m};
`

const LinkButtonWrapper = styled.div`
  & > button {
    text-align: left;
  }
  padding-top: ${styles.space.s};
  padding-bottom: ${styles.space.s};
  ${(props) => props.leftPad && `padding-left: ${styles.space.m};`}
`

function maybeGetInitialEventChainingAction({
  participationWasConfirmedOnPageload,
  participationWasCancelledOnPageload,
}) {
  if (participationWasConfirmedOnPageload) {
    return EventChainingAction.CONFIRMED
  }
  if (participationWasCancelledOnPageload) {
    return EventChainingAction.CANCELLED
  }
  return null
}

export default function ParticipationItem({
  participation,
  updateParticipation,
  updateGroupMemberStatus,
  addGroupMemberSignup,
  showDetails,
  feedbackModalOpenOnLoad,
  person,
  participationWasConfirmedOnPageload,
  participationWasCancelledOnPageload,
  initialRecommendedTimeslots,
}) {
  const [lastEventChainingAction, setLastEventChainingAction] = useState(
    maybeGetInitialEventChainingAction({
      participationWasConfirmedOnPageload,
      participationWasCancelledOnPageload,
    })
  )
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(
    feedbackModalOpenOnLoad
  )
  const [shareModalOpen, setShareModalOpen] = useState(false)
  const [detailedFeedback, setDetailedFeedback] = useState(
    participation.experience_feedback_text || ''
  )
  const [recommendedTimeslots, setRecommendedTimeslots] = useState(
    initialRecommendedTimeslots
  )

  const handleCheckInUpdate = async (attendee_check_in_status) =>
    updateParticipation(participation.id, {attendee_check_in_status})

  const handleStatusChange = async (status) => {
    const response = await updateParticipation(participation.id, {status})
    if (!response) return

    let eventChainingAction = null
    if (status === ParticipationStatus.CANCELLED) {
      eventChainingAction = EventChainingAction.CANCELLED
    } else if (status === ParticipationStatus.CONFIRMED) {
      eventChainingAction = EventChainingAction.CONFIRMED
    }

    setLastEventChainingAction(eventChainingAction)
  }

  const handleFeedbackType = async (feedbackType) => {
    const response = await updateParticipation(participation.id, {
      feedback_type: feedbackType,
    })
    if (
      // Don't open another modal over the existing one
      !feedbackModalOpen &&
      response &&
      feedbackType === ExperienceFeedbackType.APPROVED_OF_SHIFT
    ) {
      setLastEventChainingAction(EventChainingAction.LEFT_POSITIVE_FEEDBACK)
      setRecommendedTimeslots(response.recommended_timeslots)
    }
  }

  const onRequestCloseChainingModalStepper = () => {
    setLastEventChainingAction(null)
  }

  const handleFeedbackText = async (e) => {
    e.preventDefault()
    const response = await updateParticipation(participation.id, {
      feedback_text: detailedFeedback,
    })
    setFeedbackModalOpen(false)
    if (
      response &&
      participation.experience_feedback_type ===
        ExperienceFeedbackType.APPROVED_OF_SHIFT
    ) {
      setLastEventChainingAction(EventChainingAction.LEFT_POSITIVE_FEEDBACK)
      setRecommendedTimeslots(response.recommended_timeslots)
    }
  }

  const onFeedbackChange = (detailedFeedback) => {
    setDetailedFeedback(detailedFeedback)
  }

  const showDetailsClicked = (e) => {
    e.preventDefault()
    showDetails()
  }

  const getSignupIdentityFieldsFromPerson = () => {
    return {
      email: person.email,
      zip: person.zipcode,
      phone: person.phone,
      firstName: person.first_name,
      lastName: person.last_name,
      ...EMPTY_STREET_ADDRESS_FIELDS_FOR_IDENTITY,
    }
  }

  const generateModalList = () => {
    const modals = []
    if (
      lastEventChainingAction === EventChainingAction.CONFIRMED &&
      event.post_signup_asks.includes(PostSignupAsk.SOCIAL_SHARING)
    ) {
      modals.push(ModalTypes.SHARE)
    }

    // Some users who were dashboard users before they had any volunteer activity do not have these
    // all filled out on their user record, so don't show the signup chaining modal if they have
    // incomplete signup fields
    if (
      event.post_signup_asks.includes(PostSignupAsk.EVENT_SUGGESTIONS) &&
      isEveryIdentityFieldFilledOut(getSignupIdentityFieldsFromPerson())
    ) {
      modals.push(ModalTypes.SIGNUP)
    }

    const orgHasValidDonationConfig = hasValidDonationPostSignupAskConfig(
      event.organization
    )
    const showDonationModalIfPossible = event.post_signup_asks.includes(
      PostSignupAsk.DONATION
    )

    // An incomplete way of computing this that does not consider co-ownership for now
    const wasCrossPromotion =
      participation.affiliation &&
      participation.affiliation.id !== event.organization.id
    if (
      orgHasValidDonationConfig &&
      !wasCrossPromotion &&
      showDonationModalIfPossible
    ) {
      modals.push(ModalTypes.DONATE)
    }
    return modals
  }

  const {
    attendee_check_in_status,
    event,
    experience_feedback_text: experienceFeedbackText,
    experience_feedback_text_updated_at_tz: experienceFeedbackTextUpdatedAtTz,
    experience_feedback_type: experienceFeedbackType,
    has_ended: hasEnded,
    is_between_midnight_and_end: isBetweenMidnightAndEnd,
    is_within_one_hour_of_start: isWithinOneHourOfStart,
    is_within_two_days_of_start: isWithinTwoDaysOfStart,
    num_additional_reserved_participations: numAdditionalReservedParticipations,
    starts_at_tz: startsAtTz,
    starts_at_utc: startsAtUtc,
    status_attendance_combined,
    virtual_join_url: virtualJoinUrl,
    virtual_join_url_is_personalized_zoom_link: virtualJoinUrlIsPersonalizedZoomLink,
    virtual_join_url_is_zoom_link: virtualJoinUrlIsZoomLink,
  } = participation
  const isFull = isAtCapacity(participation)
  const isCancelled = status_attendance_combined === AttendanceStatus.CANCELLED
  const isCheckedIn =
    attendee_check_in_status === AttendeeCheckInStatus.COMPLETED
  const isConfirmed = status_attendance_combined === AttendanceStatus.CONFIRMED
  const isRegistered =
    status_attendance_combined === AttendanceStatus.REGISTERED

  const isCancelledAndEventFull = isCancelled && isFull

  const canCheckIn = isWithinOneHourOfStart && !isCancelled && !isCheckedIn

  // Events can be confirmed if their start date is today or yesterday, have not yet ended, are
  // not cancelled and full, and are not already confirmed or checked-in
  const participationIsAbleToBeConfirmed =
    !hasEnded && !isConfirmed && !isCancelledAndEventFull

  const canConfirm =
    isWithinTwoDaysOfStart && participationIsAbleToBeConfirmed && !canCheckIn

  const feedbackEnabled = event.volunteer_check_in_is_enabled
  // Events can be rated iff they have ended and were not cancelled
  const canRate = feedbackEnabled && hasEnded && !isCancelled
  // Events can be cancelled if they have not ended and are not already cancelled or checked-in
  const canCancel = !hasEnded && !isCancelled && !isCheckedIn
  // Events can be uncancelled if they are cancelled, have not ended, cannot be confirmed, and are
  // not at capacity
  const canUncancel = !hasEnded && isCancelled && !canConfirm && !isFull

  const showShareButton =
    !isCancelled && !hasEnded && !numAdditionalReservedParticipations
  const showConfirmedButton = isConfirmed && !canRate && !canCheckIn

  const displayTime = formatSingleStartTime(startsAtTz, event.timezone)

  const isChainingModalStepperOpen = !!lastEventChainingAction

  const shareLink =
    participation.group_invite_shortlink ||
    getOrganizationEventUrl(participation.affiliation, event, {
      rname: person.first_name,
      referring_vol: person.user_id,
      timeslot: participation.timeslot_id,
    }).toString()

  const commonSignupModalProps = {
    identityFields: getSignupIdentityFieldsFromPerson(),
    organization: participation.affiliation,
    trackingParams: EMPTY_TRACKING_PARAMS,
  }
  const oneLineAddress = getOneLineAddress(event)

  return (
    <FeedItem
      aria-label={event.name}
      dontLinkButtons
      headline={event.name}
      imageUrl={event.image_url}
      linkTo={getOrganizationEventUrl(participation.affiliation, event)}
      time={displayTime}
      firstAvailableISO={startsAtUtc}
      subhead={maybeGetEventSubheading(
        null, // no notion of a feed ID here
        event
      )}
      details={[
        ...(oneLineAddress ? [oneLineAddress] : []),

        <LinkButtonWrapper>
          <Button link padding="none" onClick={showDetailsClicked}>
            View instructions…
          </Button>
        </LinkButtonWrapper>,
      ]}
      // showCompactDivider={true}
      // TODO(jared) break up some of the actionContent render prop JSX into an intermediate
      // component or two; there is a LOT in here
      actionContent={() => (
        <div>
          {!isCancelled && isFull && (
            <Message type="warning">
              This event is full. If you cancel, you might not be able to
              register again.
            </Message>
          )}
          <ParticipationItemContainer>
            {!!numAdditionalReservedParticipations && (
              <GroupSignupManagement
                groupLeaderParticipation={participation}
                groupLeaderUser={person}
                shareLink={shareLink}
                updateParticipation={updateParticipation}
                updateGroupMemberStatus={updateGroupMemberStatus}
                addGroupMemberSignup={addGroupMemberSignup}
              />
            )}
            {canCheckIn ? (
              <Button
                icon="circle-o"
                onClick={partial(
                  handleCheckInUpdate,
                  AttendeeCheckInStatus.COMPLETED
                )}
              >
                <WideButtonContentWrapper>
                  <F defaultMessage="Check in" />
                </WideButtonContentWrapper>
              </Button>
            ) : isRegistered && canConfirm && !isCheckedIn ? (
              <Button
                icon="circle-o"
                onClick={partial(
                  handleStatusChange,
                  ParticipationStatus.CONFIRMED
                )}
              >
                <WideButtonContentWrapper>
                  <F defaultMessage="Confirm" />
                </WideButtonContentWrapper>
              </Button>
            ) : null}
            {virtualJoinUrl &&
              isBetweenMidnightAndEnd &&
              (isRegistered || isConfirmed || isCheckedIn) && (
                <JoinButton
                  isVideoCall={
                    !!(
                      virtualJoinUrlIsZoomLink ||
                      virtualJoinUrlIsPersonalizedZoomLink
                    )
                  }
                  startMoment={iso8601ToMomentInTz(startsAtTz, event.timezone)}
                  virtualJoinUrl={virtualJoinUrl}
                />
              )}
            {showShareButton && (
              <Button icon="share-alt" onClick={() => setShareModalOpen(true)}>
                <WideButtonContentWrapper>{'Share'}</WideButtonContentWrapper>
              </Button>
            )}

            {isCheckedIn && !hasEnded && (
              <Button icon="check" color={styles.colors.success300}>
                <WideButtonContentWrapper>
                  <F defaultMessage="Checked-in" />
                </WideButtonContentWrapper>
              </Button>
            )}

            {showConfirmedButton && (
              <Button icon="check" color={styles.colors.success300}>
                <WideButtonContentWrapper>
                  <F defaultMessage="Confirmed" />
                </WideButtonContentWrapper>
              </Button>
            )}

            {isCancelled && (
              <Button icon="ban" selected disabled>
                <WideButtonContentWrapper>
                  <F defaultMessage="Cancelled" />
                </WideButtonContentWrapper>
              </Button>
            )}

            {isCancelled && (canConfirm || canUncancel) && (
              <LinkButtonWrapper>
                <Button
                  link
                  padding="none"
                  onClick={partial(
                    handleStatusChange,
                    canConfirm
                      ? ParticipationStatus.CONFIRMED
                      : ParticipationStatus.REGISTERED
                  )}
                >
                  {canConfirm ? 'Confirm Instead' : 'Undo Cancellation'}
                </Button>
              </LinkButtonWrapper>
            )}

            {canCancel && (
              <Button
                secondary
                padding="none"
                onClick={partial(
                  handleStatusChange,
                  ParticipationStatus.CANCELLED
                )}
                centered={true}
              >
                <CancelButtonContentWrapper>
                  <F defaultMessage="Cancel RSVP" />
                </CancelButtonContentWrapper>
              </Button>
            )}
          </ParticipationItemContainer>
          <ParticipationItemContainer>
            {feedbackEnabled && (
              <ParticipationItemFeedback
                canRate={canRate}
                experienceFeedbackText={experienceFeedbackText}
                experienceFeedbackTextUpdatedAtTz={
                  experienceFeedbackTextUpdatedAtTz
                }
                experienceFeedbackType={experienceFeedbackType}
                isCancelled={isCancelled}
                handleFeedbackType={handleFeedbackType}
                openFeedbackModal={() => setFeedbackModalOpen(true)}
              />
            )}
          </ParticipationItemContainer>
          {feedbackEnabled && experienceFeedbackText && (
            <FeedbackWrapper>
              <Typography variant="body1">{experienceFeedbackText}</Typography>
            </FeedbackWrapper>
          )}
          {isCancelledAndEventFull && (
            <Typography variant="body2">
              This event is full, but you can find more opportunities on the{' '}
              <Link to={getOrganizationFeedUrl(participation.affiliation)}>
                {participation.affiliation.name} event feed
              </Link>
              .
            </Typography>
          )}
          {feedbackModalOpen && (
            <ParticipationItemFeedbackModal
              onFeedbackChange={onFeedbackChange}
              closeFeedbackModal={() => setFeedbackModalOpen(false)}
              detailedFeedback={detailedFeedback}
              handleFeedbackText={handleFeedbackText}
              handleFeedbackType={handleFeedbackType}
              experienceFeedbackType={experienceFeedbackType}
            />
          )}
          {lastEventChainingAction && (
            <ChainingModalStepper
              isOpen={isChainingModalStepperOpen}
              onRequestClose={onRequestCloseChainingModalStepper}
              recommendedTimeslots={recommendedTimeslots}
              chainingAction={lastEventChainingAction}
              shareLink={shareLink}
              event={event}
              shareModalType="schedule-event-chaining"
              modals={generateModalList()}
              {...commonSignupModalProps}
            />
          )}
          <ShareModal
            isOpen={shareModalOpen && !numAdditionalReservedParticipations}
            onRequestClose={() => setShareModalOpen(false)}
            shareLink={shareLink}
            event={event}
            userFirstName={person.first_name}
            modalType="schedule"
          />
        </div>
      )}
    />
  )
}
