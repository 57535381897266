import {Button, Loader} from 'components'

import TurnstileWidget from 'components/TurnstileWidget'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'

const ButtonsWrapper = styled.div({
  paddingTop: styles.space.m,
  // $FlowFixMe: this component selector is magic to Flow
  [Button]: {
    marginBottom: styles.space.s,
  },
})

export default function FormNavButtons({
  isSubmitting,
  showSecondaryButton,
  onClickPrimary,
  onClickSecondary,
  disablePrimary,
  isBackButton,
  primaryLabel,
  secondaryLabel,
  primaryId,
  secondaryId,
  showTurnstile = false,
}) {
  return isSubmitting ? (
    <Loader />
  ) : (
    <ButtonsWrapper>
      {showTurnstile ? (
        <TurnstileWidget
          action="fundraising-signup-form"
          onVerify={(token) => onClickPrimary(null, token)}
        />
      ) : (
        <Button
          data-testid={primaryId}
          disabled={disablePrimary}
          fluid
          onClick={onClickPrimary}
        >
          {primaryLabel}
        </Button>
      )}
      {showSecondaryButton && (
        <Button
          fluid
          link
          onClick={onClickSecondary}
          data-testid={secondaryId}
          icon={isBackButton ? 'angle-left' : 'angle-right'}
          iconPosition={isBackButton ? 'left-inline' : 'right-inline'}
        >
          {secondaryLabel}
        </Button>
      )}
    </ButtonsWrapper>
  )
}
