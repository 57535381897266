import IntlMessageContext from 'app/IntlMessageContext'
import Turnstile from 'react-turnstile'
import clientVars from 'util/clientVars'
import {useContext} from 'react'

export default function TurnstileWidget({action, onVerify, compact = false}) {
  const {
    intlMessageConfig: {locale},
  } = useContext(IntlMessageContext)

  return (
    <Turnstile
      sitekey={clientVars.turnstile_site_key}
      action={action}
      language={locale}
      refreshExpired="auto"
      retry="auto"
      theme="light"
      size={compact ? 'compact' : 'flexible'}
      responseField={true}
      responseFieldName="cf-turnstile-response"
      appearance="always"
      className={`${action}-turnstile`}
      onVerify={onVerify}
      data-testid="cf-turnstile-widget"
    />
  )
}
